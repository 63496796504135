import { useRef } from "react"
import AllSections from "./AllSections/AllSections"
import Presentation from "./presentation/Presentation"
import CookiesAdvise from "../cookiesAdvise/CookiesAdvise"

const KanbanPortfolio = () => {
  const allSectionsRef = useRef()
  return (
    <div className="w-[100dvw] overflow-hidden flex flex-col portfolioBackground pb-24 relative">
      <Presentation allSectionsRef={allSectionsRef} />
      <div ref={allSectionsRef}>
        <AllSections />
      </div>
    </div>
  )
}

export default KanbanPortfolio

import Section from "../AllSections/Section"
import Arch from "./Arch"
import DevelopmentSection from "./DevelopmentSection"
import MoreInfo from "./MoreInfo"
import StackGrid from "./StackGrid/StackGrid"

const Development = () => {
  return (
    <Section title={"Sobre el desarrollo"}>
      <DevelopmentSection title={"Stack utilizado"}>
        <StackGrid />
      </DevelopmentSection>
      <DevelopmentSection title={"Arquitectura de la aplicación"}>
        <Arch />
      </DevelopmentSection>
      <DevelopmentSection title={"Más información"}>
        <MoreInfo />
      </DevelopmentSection>
    </Section>
  )
}

export default Development

import {UserPage} from "./features/users/userPage/UserPage.jsx";
import AuthPanel from "./features/users/AuthPanel/AuthPanel.jsx";
import NetworkError from "./features/errors/NetworkError.jsx";
import React from "react";
import AppInterface from "./features/appInterface/AppInterface.jsx";
import ShowStaticImage from "./features/common/ShowStaticImage.jsx";
import CreateKanbanPage from "./features/kanban/CreateKanbanPage.jsx";
import MyKanbans from "./features/kanban/MyKanbans.jsx";
import KanbanEditor from "./features/kanban/kanbanEditor/KanbanEditor.jsx";
import KanbanUsers from './features/kanban/kanbanEditor/kanbanCollaborators/KanbanUsers.jsx'
import UserSearcher from './features/kanban/kanbanEditor/kanbanCollaborators/UserSearcher.jsx'
import Graph from './features/kanban/kanbanEditor/graph/Graph.jsx'
import TaskPageSelector from './features/kanban/kanbanEditor/tasks/common/TaskPageSelector.jsx'
import Home from './features/home/Home.jsx'
import TasksByCollaborator from './features/kanban/kanbanEditor/tasks/tasksByCollaborator/TasksByCollaborator.jsx'
import TasksPage from './features/kanban/kanbanEditor/tasks/tasksByPanel/TasksPage.jsx'
import {HomeRoute, ProtectedRoutes, PublicRoutes} from "./RouteGuards.jsx";
import AuthPanelContext from "./features/users/AuthPanel/AuthPanelContext.jsx";
import LoginForm from "./features/users/AuthPanel/LoginForm/LoginForm.jsx";
import * as queryParameters from "react-color/lib/helpers/index.js";
import RegisterForm from "./features/users/AuthPanel/RegisterForm/RegisterForm.jsx";
import RecoverAccountCard from "./features/users/AuthPanel/RecoverAccount/RecoverAccountCard.jsx";
import ResetPassword from "./features/users/AuthPanel/RecoverAccount/ResetPassword.jsx";
import CustomText from "./features/kanban/kanbanEditor/awareness/customTextArea/CustomText.jsx";
import NotFoundError from "./nginxErrorPages/NotFoundError.jsx";
import TooManyRequestsError from "./features/errors/ErrorPage.jsx";
import ErrorPage from "./features/errors/ErrorPage.jsx";
import TooManyRequests from "./features/errors/TooManyRequests.jsx";
import Presentation from "./features/kanbanPortfolio/presentation/Presentation.jsx";
import About from "./features/kanbanPortfolio/about/About.jsx";
import KanbanPortfolio from "./features/kanbanPortfolio/KanbanPortfolio.jsx";
import CookiesPolitics from "./features/cookiesAdvise/CookiesPolitics.jsx";
export const routes = [
    {
        path: "/register",
        element: <PublicRoutes><AuthPanelContext><AuthPanel linkRegister={true}><RegisterForm ></RegisterForm></AuthPanel></AuthPanelContext></PublicRoutes>,
    },
    {
        path: "/login",
        element:<PublicRoutes><AuthPanelContext><AuthPanel showLogo={true}><LoginForm></LoginForm></AuthPanel></AuthPanelContext></PublicRoutes>
    },
    {
        path: "/reAuth",
        element: <AuthPanelContext><AuthPanel reAuth={true} showLogo={true}><LoginForm  urlToRedirectAfterLogin={queryParameters.get("from")}></LoginForm></AuthPanel></AuthPanelContext>
    },
    {
        path: "/closeSession",
        element: <ProtectedRoutes><AuthPanelContext><AuthPanel></AuthPanel></AuthPanelContext></ProtectedRoutes>,
    },
    {
        path: "/recoverAccount",
        element: <PublicRoutes><AuthPanelContext><AuthPanel><RecoverAccountCard/></AuthPanel></AuthPanelContext></PublicRoutes>,
    },
    {
        path: "/resetPassword",
        element: <PublicRoutes><AuthPanelContext><AuthPanel linkResetPass={true}><ResetPassword/></AuthPanel></AuthPanelContext></PublicRoutes>,
    },
    {
        path: "/networkError",
        element: <NetworkError></NetworkError>,
    },
    {
        path: "/tooManyRequests",
        element: <TooManyRequests></TooManyRequests>,
    },
    {
        path: "/avatars/*",
        element: <ShowStaticImage/>,
    },
    {
        path: "/",
        element: <HomeRoute><AppInterface selectedButton={0}><Home/></AppInterface></HomeRoute>,
    },
    {
        path: "/user",
        element:  <ProtectedRoutes><AppInterface selectedButton={-1}><UserPage/></AppInterface></ProtectedRoutes>,
    },
    {
        path: "/kanbans/create",
        element: <ProtectedRoutes><AppInterface><CreateKanbanPage/></AppInterface></ProtectedRoutes>,
    },
    {
        path: "/kanbans",
        element: <ProtectedRoutes><AppInterface selectedButton={1}><MyKanbans/></AppInterface></ProtectedRoutes>,
    },
    {
        path: "/kanbanB",
        element: <ProtectedRoutes><AppInterface hideMenuInMobile={true}><KanbanEditor isKanbanEditor={true} kanEdit={true} notOverflow={true} selectedIndex={0} showEditMenu={true}/></AppInterface></ProtectedRoutes>,
    },
    {
        path: "/kanbanUsers",
        element: <ProtectedRoutes><AppInterface hideMenuInMobile={true}><KanbanEditor selectedIndex={1}><KanbanUsers/></KanbanEditor></AppInterface></ProtectedRoutes>,
    },
    {
        path: "/kanbanTasks",
        element: <ProtectedRoutes><AppInterface hideMenuInMobile={true}><KanbanEditor showProjectStats={true} kanEdit={true} selectedIndex={2}><TaskPageSelector selectedTab={1}><TasksPage/></TaskPageSelector></KanbanEditor></AppInterface></ProtectedRoutes>,
    },
    {
        path: "/collaboratorTasks",
        element: <ProtectedRoutes><AppInterface hideMenuInMobile={true}><KanbanEditor showProjectStats={true} kanEdit={true} selectedIndex={2}><TaskPageSelector selectedTab={2}><TasksByCollaborator/></TaskPageSelector></KanbanEditor></AppInterface></ProtectedRoutes>,
    },
    {
        path: "/kanbanGraph",
        element: <ProtectedRoutes><AppInterface isGraph={true} hideMenuInMobile={true} ><KanbanEditor showProjectStats={true} selectedIndex={3}><Graph/></KanbanEditor></AppInterface></ProtectedRoutes>
    },
    {
        path: "/searchUser",
        element: <UserSearcher/>,
    },
    {
        path: "/prueba",
        element: <CustomText/>,
    },
    {
        path: "/notFound",
        element: <NotFoundError notFound={true}/>,
    },
    {
        path: "/building",
        element: <NotFoundError/>,
    },
    {
        path: "/*",
        element: <NotFoundError notFound={true}/>,
    },
    {
        path: "/about",
        element: <KanbanPortfolio />,
    },
    {
        path: "/cookies",
        element: <CookiesPolitics />,
    },]
import useGoToUrl from "../common/useGoToUrl"

const CookiesPoliticsParagraph = ({ title, paragraphs }) => {
  return (
    <div>
      <h2 className="font-bold">{title}</h2>
      <div className="pl-1">
        {paragraphs.map((x, i) => (
          <p key={i}>{x}</p>
        ))}
      </div>
    </div>
  )
}

const NavigatorList = ({ navigators }) => {
  return (
    <div>
      <ul>
        {navigators.map((x, i) => (
          <li className="flex gap-1 ">
            <a>•</a> <a className="underline" href={x.link}>{x.name}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

const CookiesPolitics = () => {
  const navigate = useGoToUrl()
  return (
    <div className="p-2 flex justify-center w-full min-h-[100dvh] pb-12 pt-12">
      <div className=" max-w-[800px] h-fit bg-[#dbcbc1] rounded-xl p-2">
        <h1 className="font-bold text-lg">Política de cookies</h1>
        <CookiesPoliticsParagraph
          title={"¿Qué son las cookies?"}
          paragraphs={[
            `Las cookies son pequeños archivos de texto que se almacenan en su dispositivo cuando visita un sitio web. 
            Estas cookies permiten que el sitio funcione correctamente, recuerden sus preferencias o recopilen 
            información sobre cómo interactúa con el sitio para mejorar su experiencia de usuario.`,
          ]}
        />
        <CookiesPoliticsParagraph
          title={"¿Qué tipos de cookies utilizamos?"}
          paragraphs={[
            `En este sitio web utilizamos exclusivamente cookies estrictamente necesarias. Estas cookies son esenciales 
          para el funcionamiento básico del sitio y no requieren su consentimiento según la normativa vigente.`,
            `Se utilizan las cookies para persistir la sesión del usuario en el navegador.`,
          ]}
        />
        <CookiesPoliticsParagraph
          title={"No compartimos cookies con terceros"}
          paragraphs={[
            `Este sitio web no utiliza cookies de terceros ni comparte datos derivados de las cookies con otras 
          entidades externas.`,
          ]}
        />
        <CookiesPoliticsParagraph
          title={"Gestión de cookies"}
          paragraphs={[
            `Aunque estas cookies son necesarias y no se pueden deshabilitar en nuestro sitio, puede configurar su 
          navegador para bloquear o eliminar cookies en general. Tenga en cuenta que esto puede afectar el 
          funcionamiento correcto de la web. A continuación, se indican enlaces para gestionar cookies en los 
          navegadores más populares:`,
          ]}
        />
        <NavigatorList
          navigators={[
            {
              name: "Google Chrome",
              link: "https://support.google.com/chrome/answer/95647",
            },
            {
              name: "Mozilla Firefox",
              link: "https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox",
            },
            {
              name: "Microsoft Edge",
              link: "https://support.microsoft.com/en-US/help/4027947/microsoft-edge-delete-cookies",
            },
            {
              name: "Safari (Mac)",
              link: "https://help.apple.com/safari/mac/8.0/en.lproj/sfri11471.html",
            },
            {
              name: "Opera",
              link: "https://help.opera.com/en/latest/web-preferences/#cookies",
            },
          ]}
        />
        <CookiesPoliticsParagraph
          title={"Actualización de la política de cookies"}
          paragraphs={[
            `Esta política puede actualizarse periódicamente para reflejar cambios legales o técnicos. Le recomendamos 
          revisarla regularmente para mantenerse informado.`,
            `Última actualización: 11/20/24.`,
          ]}
        />
        <div className="flex justify-end p-4">
          <button onClick={()=>{navigate("/login")}}
          className=" bg-orange-500 rounded-xl p-2 font-bold text-gray-200">
            Volver
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookiesPolitics

const AnimatedFeatureCaptions = ({ captions, imgWidthPercent }) => {
  return (
    <div
      data-aos="fade-in"
      data-aos-duration="2000"
      className={`h-full mt-auto mb-auto w-full portrait:w-[${
        100 - imgWidthPercent
      }%}]`}
    >
      <div
        className="flex flex-col justify-center h-full  pl-4 pr-2 sm:pl-12 sm:pr-12 w-full text-white font-bold text-lg sm:portrait:text-xl lg:landscape:text-xl xl:landscape:text-2xl xl:portrait:text-2xl"
      >
        <ul className="list-disc ">
          {captions.map((x,i) => (
            <li key={i} className="mt-2 mb-2">{x}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const AnimatedFeatureImg = ({ imgWidthPercent, img, fromRight }) => {
  return (
    <div
      className={`h-full mt-auto mb-auto w-full portrait:w-[${
         imgWidthPercent
      }%}]`}
    >
      <div
        data-aos={fromRight==true?"fade-left":"fade-right"}
        data-aos-duration="2000"
        className=" h-fit w-full"
      >
        <div className="flex justify-center overflow-hidden ">
          <img className=" rounded-xl" src={img} />
        </div>
      </div>
    </div>
  )
}

const AnimatedFeature = ({ img, captions, imgWidthPercent, fromRight }) => {
  return (
    <div className={`flex portrait:flex-col ${fromRight?"flex-row-reverse":"flex-row"}  justify-between w-full h-fit`}>
      <AnimatedFeatureImg imgWidthPercent={imgWidthPercent} img={img}  fromRight={fromRight} />
      <AnimatedFeatureCaptions
        captions={captions}
        imgWidthPercent={imgWidthPercent}
      />
    </div>
  )
}

export default AnimatedFeature

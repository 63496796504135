import Section from "../AllSections/Section"
import AnimatedFeature from "./AnimatedFeature/AnimatedFeature"

const Features = () =>{
    return(
        <Section title={"Funcionalidades"}>
          <AnimatedFeature
            img={"/presentationAssets/layout.gif"}
            captions={[
              "Customiza el layout del tablero.",
              "Divide cada panel vertical u horizontalmente de forma recursiva y desplaza las divisiones para lograr la configuración deseada.",
            ]}
            imgWidthPercent={40}
            fromRight={false}
          />
          <AnimatedFeature
            img={"/presentationAssets/moverNotas.gif"}
            captions={[
              'Añade notas al tablero para representar tareas.',
              'Desplaza las notas libremente por el tablero, el sistema se encargará de calcular en que panel se encuentran.',
              'Modifica el layout sin miedo. Las notas se mantendrán en el panel en el que estaban.',
              ]}
            imgWidthPercent={30}
            fromRight={true}
          />
          <AnimatedFeature
            img={"/presentationAssets/parentTasks.gif"}
            captions={[
              'Añade propiedades enfocadas a SCRUM a las tareas.',
              'Asigna un encargado a cada tarea.',
              'Agrupa tareas asignándoles una tarea padre.',
              'Asigna un peso (dificultad) y el porcentaje de completitud.',
              'El peso de las tareas padre es la suma del de sus hijas y el porcentaje de completitud se calcula como la media ponderada de estas.',
              ]}
            imgWidthPercent={25}
            fromRight={false}
          />
          <AnimatedFeature
            img={"/presentationAssets/tareas.gif"}
            captions={[
              'Obtén estadísticas sobre el progreso de las tareas filtrando por el panel del tablero en el que se encuentran o por miembro del equipo.',
              'Edita los datos de las tareas desde una vista más técnica.',
              ]}
            imgWidthPercent={35}
            fromRight={true}
          />
          <AnimatedFeature
            img={"/presentationAssets/grafo.gif"}
            captions={[
              'Visualiza un grafo de dependencia de las tareas en función de su relación padre-hijo con los porcentajes y pesos de completitud y el trabajador asignado.',
              ]}
            imgWidthPercent={30}
            fromRight={false}
          />
          <AnimatedFeature
            img={"/presentationAssets/awareness.gif"}
            captions={[
              'Trabaja colaborativamente con otros usuarios en el tablero.',
                  'Además de ver los cambios realizados por otros usuarios a tiempo real podrás ver sus cursores, incluso trabajando desde diferentes vistas.',
                  'También podrás deshacer y rehacer los cambios que realices, independientemente de que haya otros usuarios realizando cambios al mismo tiempo'
              ]}
            imgWidthPercent={40}
            fromRight={true}
          />
          <AnimatedFeature
            img={"/presentationAssets/responsive.png"}
            captions={[
              'Trabaja desde cualquier dispositivo.',
                  'La aplicación es responsive y adaptada para pantallas táctiles.',
              ]}
            imgWidthPercent={25}
            fromRight={false}
          />
        </Section>
    )
}

export default Features
const SectionMenuButton = ({name, buttonRef}) =>{
    return(
        <button className="group text-white text-xl" onClick={()=>buttonRef.current?.scrollIntoView()}>
  <a>• </a><a className="group-hover:underline ">{name}</a>
</button>

    )
}

const SectionMenu = ({aboutRef, featuresRef, developmentRef}) =>{
    return(
        <div className=" flex justify-center items-center">
  <div className="flex flex-col sm:flex-row justify-center items-start gap-4">
    <SectionMenuButton name='Sobre el proyecto' buttonRef={aboutRef}></SectionMenuButton>
    <SectionMenuButton name='Funcionalidades' buttonRef={featuresRef}></SectionMenuButton>
    <SectionMenuButton name='Sobre el desarrollo' buttonRef={developmentRef}></SectionMenuButton>
  </div>

</div>
    )
}
export default SectionMenu
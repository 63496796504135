import About from "../about/About"
import AnimatedFeature from "../features/AnimatedFeature/AnimatedFeature"
import Arch from "../development/Arch"
import Development from "../development/Development"
import DevelopmentSection from "../development/DevelopmentSection"
import MoreInfo from "../development/MoreInfo"
import StackGrid from "../development/StackGrid/StackGrid"
import Section from "./Section"
import Features from "../features/Features"
import SectionMenu from "./SectionMenu"
import { useRef } from "react"

const AllSections = () => {
  const aboutRef = useRef()
  const featuresRef = useRef()
  const developmentRef = useRef()
  return (
    <div className="w-full flex flex-col gap-4 pt-6 ">
      <SectionMenu aboutRef={aboutRef} featuresRef={featuresRef} developmentRef={developmentRef}
        
      />
      <div className="flex flex-col w-full ml-auto mr-auto 2xl:w-[1536px] p-2 gap-8 overflow-x-visible">
        <div ref={aboutRef}>
        <About/>
        </div>
        <div ref={featuresRef}>
        <Features/>
        </div>
        <div ref={developmentRef}>
        <Development/>
        </div>
      </div>
    </div>
  )
}
export default AllSections

import { useEffect, useState } from "react"
import AuthPanelBackground from "./common/AuthPanelBackground.jsx"
import { useAuthContext } from "./AuthPanelContext.jsx"
import CookiesAdvise from "../../cookiesAdvise/CookiesAdvise.jsx"

const AuthPanel = (props) => {
  const { showLogo, reAuth, children } = props
  const authContext = useAuthContext()
  const { isReAuth, setIsReauth } = authContext
  useEffect(() => {
    if (reAuth) {
      setIsReauth(true)
      sessionStorage.removeItem("TOKEN")
      localStorage.removeItem("TOKEN")
    }
  }, [reAuth, setIsReauth])

  return (
    <div>
      {isReAuth && (
        <div
          id={"hbar"}
          className={"w-full bg-red-800 flex flex-row flex-wrap"}
        >
          <a className="text-white ml-2">
            Tu sesión ha caducado. Vuelve a iniciar sesión para continuar.
          </a>
        </div>
      )}
      <AuthPanelBackground showLogo={showLogo}>
        {children}
        <CookiesAdvise />
      </AuthPanelBackground>
    </div>
  )
}

export default AuthPanel

import { Navigate, useLocation } from "react-router-dom"
import {
  getServiceToken,
  loginFromToken,
  removeServiceToken,
} from "./backend/userService.js"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import useGoToUrl from "./features/common/useGoToUrl.jsx"
const loginStateValues = {
  NOT_CHECKED: -1,
  NO_TOKEN: 0,
  WRONG_TOKEN: 1,
  GOOD_TOKEN: 2,
}

const useGetLoginStatus = () => {
  const [loginState, setLoginState] = useState(loginStateValues.NOT_CHECKED)
  const dispatch = useDispatch()
  useEffect(() => {
    if (getServiceToken() != null) {
      dispatch(
        loginFromToken(
          getServiceToken(),
          () => {
            setLoginState(loginStateValues.GOOD_TOKEN)
          },
          () => {
            setLoginState(loginStateValues.WRONG_TOKEN)
          },
        ),
      )
    } else {
      setLoginState(loginStateValues.NO_TOKEN)
    }
  }, [])
  return { loginStatus: loginState }
}

export const PublicRoutes = (props) => {
  const location = useLocation()
  const { children } = props
  const { loginStatus } = useGetLoginStatus()
  const navigate = useGoToUrl()
  console.log(loginStatus == loginStateValues.NO_TOKEN)
  if (loginStatus == loginStateValues.NOT_CHECKED) return
  if (loginStatus == loginStateValues.NO_TOKEN || loginStatus == loginStateValues.WRONG_TOKEN) return children
  if (loginStatus == loginStateValues.GOOD_TOKEN)
    return <Navigate to="/" state={{ from: location }} replace />
}

export const HomeRoute = (props) => {
  const location = useLocation()
  const { children } = props
  const { loginStatus } = useGetLoginStatus()

  if (loginStatus == loginStateValues.NOT_CHECKED) return
  if (loginStatus == loginStateValues.NO_TOKEN)
    return <Navigate to="/about" state={{ from: location }} replace />
  if (loginStatus == loginStateValues.GOOD_TOKEN) return children
  if (loginStatus == loginStateValues.WRONG_TOKEN)
    return (
      <Navigate
        to={"/reAuth?from=" + window.location.pathname + window.location.search}
        state={{ from: location }}
        replace
      />
    )
}

export const ProtectedRoutes = (props) => {
  const location = useLocation()
  const { children } = props
  const { loginStatus } = useGetLoginStatus()

  if (loginStatus == loginStateValues.NOT_CHECKED) return
  if (loginStatus == loginStateValues.GOOD_TOKEN) return children
  if (loginStatus == loginStateValues.WRONG_TOKEN)
    return (
      <Navigate
        to={"/reAuth?from=" + window.location.pathname + window.location.search}
        state={{ from: location }}
        replace
      />
    )
  if (loginStatus == loginStateValues.NO_TOKEN)
    return <Navigate to="/login" state={{ from: location }} replace />
}

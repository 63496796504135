import { useEffect, useState } from 'react'
import { TaskTableHeader } from '../taskRows/TaskRow.jsx'
import 'react-circular-progressbar/dist/styles.css';
import CircularProgress from '../common/CircularProgress.jsx'
import DefaultImage from '../../../../common/DefaultImage.js'
import useUserNameCache from '../../useUserNameCache.jsx'

const Collaborator = ({ collaboratorY, allTasks, tasksY }) => {
  const collaboratorsCache = useUserNameCache()
  const [image, setImage] = useState(null)
  const [name, setName] = useState("")
  const [userName, setUserName] = useState("")
  const [thisUserTaskIndexes, setThisUserTaskIndexes] = useState({finalized: [], notFinalized: []})
  const [totalWork, setTotalWork] = useState({})
  const [notFinalizedTaskCompletedWork, setNotFinalizedTaskCompletedWork] = useState({})
  const [showFinalizedTasks, setShowFinalizedTasks] = useState(false)
  const [showNotFinalizedTasks, setShowNotFinalizedTasks] = useState(false)

  const getUserTasks = (tasksY, collaboratorY) => {
    let finalized = []
    let notFinalized = []
    tasksY.map((x, i) => {
      if (x.get('props').get('isAssigned') && x.get('props').get('assignedTo') === collaboratorY.get("id"))
        if(x.get('props').get('completenessActive') && Number(x.get('props').get('completenessPercent'))===100)
          finalized.push(i)
        else
          notFinalized.push(i)
    })
    return {finalized: finalized, notFinalized: notFinalized, percent: (notFinalized.length+finalized.length>0)?Number(((finalized.length/(notFinalized.length+finalized.length))*100).toString().split(".")[0]): 100}
  }

  const getAllTaskWorkPercent = (tasksY) =>{
    let totalWeight = 0
    let acuPercent = 0
    tasksY.map((x, i) => {
      if (x.get('props').get('isAssigned') && x.get('props').get('assignedTo') === collaboratorY.get("id") && x.get('props').get('completenessActive')){
        totalWeight+=Number(x.get('props').get('completenessWeight'))
        acuPercent+=x.get('props').get('completenessWeight')*x.get('props').get('completenessPercent')/100
      }
    })
    return {totalWeight: totalWeight, weightDone: acuPercent, percent: totalWeight===0?100:Number(((acuPercent/totalWeight)*100).toString().split(".")[0])}
  }
  const getNotFinalizedTaskWorkPercent = (tasksY) =>{
    let totalWeight = 0
    let acuPercent = 0
    tasksY.map((x, i) => {
      if (x.get('props').get('isAssigned') &&
        x.get('props').get('assignedTo') === collaboratorY.get("id") &&
        x.get('props').get('completenessActive') &&
        x.get('props').get('completenessPercent')<100){
        totalWeight+=Number(x.get('props').get('completenessWeight'))
        acuPercent+=x.get('props').get('completenessWeight')*x.get('props').get('completenessPercent')/100
      }
    })
    return {totalWeight: totalWeight, weightDone: acuPercent, percent: totalWeight===0?100:Number(((acuPercent/totalWeight)*100).toString().split(".")[0])}
  }


  useEffect(()=>{
    let ob
    tasksY.observeDeep(ob=e=>{
      setTotalWork(getAllTaskWorkPercent(tasksY))
      setNotFinalizedTaskCompletedWork(getNotFinalizedTaskWorkPercent(tasksY))
      setThisUserTaskIndexes(getUserTasks(tasksY, collaboratorY))
    })
    setTotalWork(getAllTaskWorkPercent(tasksY))
    setNotFinalizedTaskCompletedWork(getNotFinalizedTaskWorkPercent(tasksY))
    setThisUserTaskIndexes(getUserTasks(tasksY, collaboratorY))
    return()=>tasksY.unobserveDeep(ob)
  }, [])

  useEffect(() => {
    const defaultImg= DefaultImage
    let imgRoute = defaultImg
    if (collaboratorsCache[collaboratorY.get('id')]&&!collaboratorsCache[collaboratorY.get('id')].defaultimg)
      imgRoute = import.meta.env.VITE_BACKEND_URL + collaboratorsCache[collaboratorY.get('id')].imgdata
    setImage(<img className={"rounded-2xl w-12 h-12"} src={imgRoute}/>)
    collaboratorsCache[collaboratorY.get('id')]&& setName(collaboratorsCache[collaboratorY.get('id')].name)
    collaboratorsCache[collaboratorY.get('id')]&&setUserName(collaboratorsCache[collaboratorY.get('id')].username)
  }, [collaboratorsCache])

  return (
    <div className={`bg-color3 rounded-2xl flex flex-col  border-2 border-white pb-4 pl-2 pr-2 gap-2 text-white select-none`}>
      <div className={"flex flex-col sm:flex-row justify-between pl-2 pt-2"}>
        <div className={"flex flex-row gap-2"}>
          {image}
          <div className={"flex flex-col gap-1 text-white"}>
            <a className={"font-bold"}>{name}</a>
            <a>@{userName}</a>
          </div>
        </div>

        <div className={"flex flex-row gap-4 items-start"}>
          <CircularProgress text={"Tareas completadas"} percent={thisUserTaskIndexes.finalized.length+thisUserTaskIndexes.notFinalized.length>0?
            thisUserTaskIndexes.percent: 100} done={thisUserTaskIndexes.finalized.length} total={thisUserTaskIndexes.finalized.length+thisUserTaskIndexes.notFinalized.length}/>
          <CircularProgress text={"Trabajo completado"} percent={totalWork.percent} done={totalWork.weightDone} total={totalWork.totalWeight}/>
          <CircularProgress text={"Trabajo completado (tareas sin finalizar)"} percent={notFinalizedTaskCompletedWork.percent} done={notFinalizedTaskCompletedWork.weightDone} total={notFinalizedTaskCompletedWork.totalWeight}/>
        </div>
      </div>
      <div className={"flex flex-col text-white gap-2"}>

        <div className={"flex flex-col"}>
          <div className={"flex gap-2 items-center"}>
            <a className={"font-bold"}>Tareas sin finalizar: {thisUserTaskIndexes.notFinalized.length}</a>
            {thisUserTaskIndexes.notFinalized.length>0&&<a className={'text-xs hover:underline hover:cursor-pointer select-none'}
                onClick={() => setShowNotFinalizedTasks(!showNotFinalizedTasks)}>{showNotFinalizedTasks ? 'ocultar' : 'mostrar'}</a>}
          </div>

          {showNotFinalizedTasks&&
            (thisUserTaskIndexes.notFinalized.length>0 &&
            <div className={"flex flex-col gap-2"}>
              <div className={"flex flex-row"}>

              </div>
              <div>
                <TaskTableHeader/>
                {allTasks.map((x, i) => {
                  if (thisUserTaskIndexes.notFinalized.find(x => x === i) > -1)
                    return x
                })}
              </div>

          </div>)}

        </div>
        <div>
          <div className={"flex gap-2 items-center"}>
            <a className={"font-bold"}>Tareas finalizadas: {thisUserTaskIndexes.finalized.length}</a>
            {thisUserTaskIndexes.finalized.length>0&&<a className={'text-xs hover:underline hover:cursor-pointer select-none'}
                onClick={() => setShowFinalizedTasks(!showFinalizedTasks)}>{showFinalizedTasks ? 'ocultar' : 'mostrar'}</a>
            }          </div>
          {showFinalizedTasks&&(thisUserTaskIndexes.finalized.length>0&&
            <div>
              <TaskTableHeader/>
              {allTasks.map((x, i) => {
                if (thisUserTaskIndexes.finalized.find(x => x === i) > -1)
                  return x
              })}
            </div>)}
        </div>

      </div>


    </div>
  )
}

export default Collaborator
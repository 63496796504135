const DevelopmentSection = ({title, children}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <h3 className="text-white text-3xl font-bold">• { title }</h3>
      {children}
    </div>
  )
}

export default DevelopmentSection

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowLeft, faPlus, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import BackgroundImg from "/presentationAssets/background.gif"
import useGoToUrl from '../../common/useGoToUrl';
const Presentation = ({allSectionsRef}) => {
    useEffect(() => {
        AOS.init();
      }, [])
      const navigate = useGoToUrl()      
    return (
            <div className=" flex flex-col justify-between w-full h-full tall:h-[100dvh] bg-black overflow-hidden tall:overflow-visible relative">
                <img className="absolute z-0 object-cover w-full h-full blur opacity-40" src={BackgroundImg} />
                <div className="relative w-full flex flex-col text-white h-full justify-center items-center  z-50 pb-8 select none gap-4 lg:gap-8">
                    <div className="flex justify-center items-center w-full pt-4">
                        <h1 className="text-4xl lg:text-6xl font-bold text-center" data-aos="fade-in" data-aos-delay="100" data-aos-duration="3000">Tablero Kanban colaborativo</h1>
                    </div>
                    <div className="w-full lg:w-auto flex flex-col lg:flex-row items-center justify-center pl-12 pr-12 h-fit lg:gap-8 gap-4">
                        <div className="h-full flex flex-col justify-center w-full gap-2">
                            <div className=" text-lg lg:text-3xl font-bold" data-aos="fade-in" data-aos-delay="500" data-aos-duration="1800">
                                <ul className="list-disc">
                                    <li>Crea y gestiona tableros Kanban enfocados a scrum, compartidos a tiempo real entre múltiples usuarios.</li>
                                </ul>
                            </div>
                            <div className="flex justify-center mt-2 w-full h-fit" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000">
                                <button onClick={()=>navigate("/login")} className="bg-black p-2 rounded-2xl border-2 border-white font-bold text-xl lg:text-2xl hover:bg-orange-600"><a className="hover:animate-pulse">Ir a la aplicación</a></button>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-2 absolute flex justify-end w-full pr-4 gap-1" data-aos="fade-in" data-aos-offset="-3000" data-aos-delay="1300" data-aos-duration="1300">
                        <a>Creado por </a><a className="underline hover:text-blue-300 hover:cursor-pointer" href="https://jaimejcorral.com">Jaime José Corral García</a></div>
                </div>

                <div  onClick={()=>allSectionsRef.current?.scrollIntoView()}
                 className="bg-black text-white cursor-pointer select-none w-full h-12 flex justify-center items-center p-1 border-t-2  z-50 portrait:h-24 sm:portrait:h-12 ">
                    <div className="flex gap-2 items-center justify-center absolute animate-pulse " data-aos="fade-in" data-aos-offset="-3000" data-aos-delay="200" data-aos-duration="300">
                        <a className="text-2xl font-bold" >Más información</a>
                        <FontAwesomeIcon icon={faArrowDown} color="white" size={"lg"}/>
                    </div>
                </div>

            </div>
    )
}

export default Presentation
import Section from "../AllSections/Section"
import BoldText from "../boldText/BoldText"


const About = () =>{
    return(
      <Section title={"Sobre el proyecto"}>
        <div className="p-2 flex flex-col gap-4 text-gray-400 text-xl pt-0">
        <div>
          <p>
            Inicié este proyecto como <BoldText>trabajo de fin de grado</BoldText> para el <BoldText> 
              grado en ingeniería informática de la Universidad de la Coruña</BoldText> en la 
              especialidad de <BoldText>ingeniería software</BoldText>, logrando con él la <BoldText>
                matrícula de honor en el TFG</BoldText>.
          </p>
        </div>
        <p>
          La aplicación consiste en un <BoldText>tablero estilo Kanban enfocado a SCRUM</BoldText>, que permita
          manipular un tablero <BoldText>similar a uno físico</BoldText>, con la <BoldText>flexibilidad y facilidad de uso</BoldText> que esto conlleva,
          teniendo además todas las <BoldText>ventajas de tenerlo informatizado</BoldText>.
        </p>
        <p>
          Una de las <BoldText>principales motivaciones</BoldText> para crear esta aplicación fue observar la <BoldText>falta de flexibilidad de la mayoría
          de soluciones</BoldText> que se encuentran actualmente en el mercado, causando que <BoldText>muchos equipos SCRUM sigan utilizando
          tableros físicos</BoldText> a día de hoy.
        </p>
        <p>
          Puesto que <BoldText>se pretende que esta aplicación sea un sustitutivo para los tableros físicos</BoldText>, uno de los requisitos ha
          sido que la aplicación sea <BoldText>colaborativa a tiempo real</BoldText>, pudiendo varios usuarios interactuar con el
          tablero simultánemente.
        </p>
      </div>  
      </Section>
    )
}

export default About
import { useState } from "react"
import { getCookiesAcepted, setCookiesAcepted } from "../../backend/userService"
import useGoToUrl from "../common/useGoToUrl"

const CookiesAdvise = () => {
  const navigate = useGoToUrl()
  const cookiesAccepted = getCookiesAcepted()
  const [hide,setHide] = useState(cookiesAccepted)
  if(hide||cookiesAccepted)
    return
  return (
    <div className="bg-[#dbcbc1] fixed right-4 bottom-12 rounded-2xl h-fit z-[200] flex gap-2 items-center p-2 w-72">
      <img src="/cookies.png" className="h-24" />
      <div className="flex flex-col items-end gap-1">
        <p className="text-sm">
          Este sitio web utiliza cookies estrictamente necesarias para su
          funcionamiento.
        </p>
        <a className="text-sm underline cursor-pointer" onClick={()=>navigate("/cookies")}>Política de cookies</a>
        <button onClick={()=>{setCookiesAcepted(); setHide(true)}}
        className="bg-orange-500 text-gray-200 w-fit rounded-xl pt-1 pb-1 p-2 font-bold">Vale</button>
      </div>
    </div>
  )
}

export default CookiesAdvise

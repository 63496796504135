import BoldText from "../boldText/BoldText"

const MoreInfo = ()=>{
    return(
    <div className="pl-2 text-gray-400 text-xl flex flex-col gap-2">
      <p>
        Para la implementación del <BoldText>backend</BoldText> se ha utilizado el lenguaje <BoldText>Go</BoldText>, junto con el <BoldText>framework HTTP fiber</BoldText>, la elección
        de estas tecnologías se ha basado en su <BoldText>velocidad de funcionamiento</BoldText> y para aprender estas herramientas que eran
        desconocidas para mi hasta el momento.
      </p>
      <p>
        En cuanto al <BoldText>frontend</BoldText> se ha implementado utilizando <BoldText>react</BoldText> junto a <BoldText>redux 
          para persistir la sesión de usuario</BoldText> y <BoldText>tailwind como framework css</BoldText>.
      </p>
      <p>
        Para implementar la <BoldText>sincronización</BoldText> entre clientes se ha utilizado <BoldText>Yjs</BoldText>, 
        una implementación en JS de un <BoldText>CRDT</BoldText> que permite <BoldText>compartir datos a tiempo real entre 
          distintos clientes</BoldText>.<br/>
        La forma de <BoldText>conexión entre los clientes</BoldText> es mediante un <BoldText>servidor central de sincronización</BoldText> que además se encarga
        de la <BoldText>autenticación sobre los datos compartidos y su persistencia en la base de datos</BoldText>.
      </p>
      <p>
        La aplicación está <BoldText>desplegada en Kubernetes</BoldText>.
      </p>
      <p className="font-bold text-white">
        Si quieres ver más información sobre el desarrollo puedes consultar la <a className="underline hover:text-blue-300" href="/memoria_tfg.pdf">memoria del TFG</a>.
      </p>
    </div>
    )
}

export default MoreInfo
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { searchCollaborators } from '../../../../backend/userService.js'
import * as Y from 'yjs'
import DefaultImage from '../../../common/DefaultImage.js'
import { addCollaborator, findCollaboratorIndexById } from '../../../../yjs/functions/YjsCollaboratorFunctions.js'

const UserSearcherRow = ({
  kanbanId,
  collaboratorId,
  name,
  username,
  mail,
  isDefaultImg,
  alreadyAdded,
  setSearchField,
  imgData,
  color
}) => {
  const defaultImg= DefaultImage
  const imgRoute = isDefaultImg ? defaultImg : import.meta.env.VITE_BACKEND_URL+imgData
  const imageSize = 65
  const dispatch = useDispatch()
  const [alreadyAddedX, setAlreadyAddedX] = useState(false)
  const addIcon = <FontAwesomeIcon icon={faPlus} color="white" size={'xl'}/>
  const checkIcon = <FontAwesomeIcon icon={faCheck} color="white" size={'xl'}/>
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const user = useSelector((state) => state.user.userInfo)

  const keepLength = (value, length) => {
    return value.length < length ? value : (value.substring(0, length - 1) + '...')
  }
  useEffect(() => {
    const collaboratorIndex = findCollaboratorIndexById(yjsData.collaboratorsY, collaboratorId)
    setAlreadyAddedX(collaboratorIndex !== -1)
  }, [alreadyAdded])

  useEffect(() => {
    yjsData.collaboratorsY.observeDeep(e => {
      const collaboratorIndex = findCollaboratorIndexById(yjsData.collaboratorsY, collaboratorId)
      setAlreadyAddedX(collaboratorIndex !== -1)
    })
  }, [])

  return (
    <div className={'w-full bg-color5 text-white flex flex-row p-1 justify-between'}>
      <div className={'flex flex-row gap-2'}>
        <img style={{
          borderColor: color,
          borderWidth: '5px',
          minWidth: imageSize + 'px',
          width: imageSize + 'px',
          height: imageSize + 'px'
        }} src={imgRoute}
             className={'rounded-2xl shrink-0 w-full h-full '}/>
        <div className={'flex flex-col'}>
          <a>{keepLength('@' + username, 27)}</a>
          <a className={'text-m'}>{keepLength(name, 27)}</a>
          <a className={'text-sm'}>{keepLength(mail, 27)}</a>
        </div>
      </div>
      <div className={'flex items-center p-2'}>
        {alreadyAddedX ?
          <div>
            {checkIcon}
          </div> :
          <div className={'cursor-pointer'}
               onClick={() => {
                 addCollaborator(collaboratorId, yjsData.collaboratorsY)
                 setAlreadyAddedX(true)
                 setSearchField('')
               }}>
            {addIcon}
          </div>
        }

      </div>
    </div>
  )
}

const UserSearcher = ({ kanbanId }) => {
  const [searchField, setSeatchFiled] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(searchCollaborators(searchField,
      kanbanId, 0, 'name', 1,
      10,
      (data) => {
        setSearchResult(data)
      }))
  }, [searchField])
  return (
    <div className={'bg-color3 w-full rounded-2xl flex flex-col p-4 gap-2'}>
      <input className={'text-white text-lg bg-color4'} placeholder={'Busca para añadir'} value={searchField}
             onChange={e => setSeatchFiled(e.target.value)}/>
      <div className={'w-full h-48 bg-color4 overflow-scroll'}
           style={{ display: searchField.length > 0 ? '' : 'none' }}>
        {searchField.length > 0 && searchResult.users && searchResult.users.map((x, i) => {
          return <UserSearcherRow setSearchField={setSeatchFiled} collaboratorId={x.id}
                                  kanbanId={kanbanId} key={x.id}
                                  name={x.name} username={x.username}
                                  mail={x.mail} isDefaultImg={x.defaultimg} imgData={x.imgdata}
                                  alreadyAdded={x.alreadyadded}
                                  color={x.color}/>
        })}
      </div>
    </div>
  )
}

export default UserSearcher
const Section = ({ title, children }) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <h2 className="text-white lg:text-5xl text-4xl font-bold">• { title }</h2>
      {children}
    </div>
  )
}

export default Section

const ValidatePanelOne = ({ condition, text, icon, index }) => {
  if (condition)
    return (
      <div id="ValidationRow" className={'flex flex-row content-center items-center w-full gap-1'} key={index}>
        <div>{icon}</div>
        <a className={'text-white ellipsis-3'}>{text}</a>
      </div>)
}

const VALIDATE_PANEL_PERCENT=60
export const ValidatePanel = ({ conditions }) => {
  let warnList = conditions.map((x, index) => <ValidatePanelOne condition={x.condition} text={x.text}
                                                                icon={x.icon} key={index}></ValidatePanelOne>)
  return (
    <div id="Validation" className={`flex flex-col justify-start w-full sm:w-[${VALIDATE_PANEL_PERCENT}%]`}>
      {warnList}
    </div>
  )
}

export const TextInput = ({ autoFocus, name, value, handleChange, goodConds, badConds, maxLength, password, dataTestId }) => {
  return (
    <div id="input" className={`w-full sm:w-[${100-VALIDATE_PANEL_PERCENT}%]`}>
      <label className="block text-white text-xl font-bold mb-2 w-full" htmlFor="fullname">
        {name}
      </label>
      <input data-testid={dataTestId} autoFocus={autoFocus} 
      className={`bg-color4 shadow appearance-none rounded w-full py-2 px-3 text-white mb-1 leading-tight 
        focus:outline-none focus:shadow-outline ${goodConds&&"border-green-600 border-2 bor"} 
        ${badConds&&"badInputClasses"} ${!goodConds && !badConds && "focus:outline-orange-500"}`}
             onChange={handleChange} maxLength={maxLength} type={password && 'password'}
             value={value}/>
    </div>
  )
}

export const RegisterFromField = ({ autoFocus, name, value, handleChange, goodConds, badConds, validatePanelConditions, maxLength, password, dataTestId  }) => {
  return (
    <div className={'w-full flex flex-col sm:flex-row  bg-color2 rounded-2xl p-2 shadow gap-2 '}>
      <TextInput autoFocus={autoFocus}
                 name={name}
                 value={value}
                 handleChange={handleChange}
                 goodConds={goodConds}
                 badConds={badConds}
                 maxLength={maxLength}
                 password={password}
                 dataTestId={dataTestId}
      />
      <ValidatePanel conditions={validatePanelConditions}/>
    </div>
  )
}
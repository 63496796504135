import Tree from "react-d3-tree"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DefaultImage from "../../../common/DefaultImage.js"
import {
  findTaskById,
  getDirectChilds,
  getStatsFromTaskList,
} from "../../../../yjs/functions/YjsTasksFunctions.js"
import useUserNameCache from "../useUserNameCache.jsx"
import { setHideMenu } from "../../../../redux/slices/kanbanSlice.js"
const maxTitleLength = 18
const generateGraphTree = (tasksY) => {
  const processOne = (x, maxTitleLength, treeChilds, out) => {
    const thisV = {
      name:
        treeChilds.length % 2 === 0 ||
        x.get("name").toString().length < maxTitleLength
          ? x.get("name").toString()
          : x.get("name").toString().slice(0, maxTitleLength) + "...",
    }
    if (treeChilds.length > 0) {
      thisV.children = treeChilds
    }

    let parentChildNumber = 0
    if (x.get("props").get("isChildTask")) {
      const parent = findTaskById(tasksY, x.get("props").get("parentTask"))
      const parentChilds = getDirectChilds(parent, tasksY)
      let activeChildCounter = 0
      parentChilds.map((x) => {
        if (x.get("props").get("completenessActive")) activeChildCounter++
      })
      parentChildNumber = activeChildCounter
    }

    let attributes = {
      completenessActive: x.get("props").get("completenessActive"),
      completenessPercent: x.get("props").get("completenessPercent"),
      completenessWeight: x.get("props").get("completenessWeight"),
      isAssigned: x.get("props").get("isAssigned"),
      assignedTo: x.get("props").get("assignedTo"),
      isChildTask: x.get("props").get("isChildTask"),
      parentChildNumber: parentChildNumber,
    }
    if (x.get("props").get("completenessActive")) {
      attributes.completado = x.get("props").get("completenessPercent") + "%"
    }
    thisV.attributes = attributes
    out.push(thisV)
  }

  const getTreeChilds = (taskY, tasksY) => {
    let childs = getDirectChilds(taskY, tasksY)
    let out = []
    childs.map((x) => {
      const thisChildNumber = getDirectChilds(x, tasksY).length
      const treeChilds = getTreeChilds(x, tasksY)
      processOne(x, maxTitleLength, treeChilds, out)
    })
    return out
  }

  let noParentTasks = []
  tasksY.map((x) => {
    if (!x.get("props").get("isChildTask")) noParentTasks.push(x)
  })
  let children = []
  noParentTasks.map((x) => {
    const treeChilds = getTreeChilds(x, tasksY)
    processOne(x, maxTitleLength, treeChilds, children)
  })

  return {
    name: "ini",
    children: children,
  }
}

const Graph = () => {
  const ref = useRef()
  const [translateHeight, setTranslateHeight] = useState(0)
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const [treeData, setTreeData] = useState(generateGraphTree(yjsData.tasksY))
  const dispatch = useDispatch()
  const collaboratorsCache = useUserNameCache()
  useEffect(() => {
    let ob
    yjsData.tasksY.observeDeep(
      (ob = (x) => setTreeData(generateGraphTree(yjsData.tasksY))),
    )
    setTranslateHeight(ref.current.getBoundingClientRect().height / 2)
    dispatch(setHideMenu(false))
    return () => {
      yjsData.tasksY.unobserveDeep(ob)
    }
  }, [])

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => {
    const defaultImg = DefaultImage
    let imgRoute = defaultImg
    if (
      nodeDatum.attributes &&
      !!nodeDatum.attributes.assignedTo &&
      collaboratorsCache[nodeDatum.attributes.assignedTo] &&
      !collaboratorsCache[nodeDatum.attributes.assignedTo].defaultimg
    )
      imgRoute =
        import.meta.env.VITE_BACKEND_URL +
        collaboratorsCache[nodeDatum.attributes.assignedTo].imgdata
    let att = nodeDatum.attributes
    if (!nodeDatum.attributes) att = {}
    let attArray = []
    if (nodeDatum.attributes) {
      Object.keys(nodeDatum.attributes).forEach(function (key, index) {
        attArray.push({ key: key, value: nodeDatum.attributes[key] })
      })
    }
    const radio = 30
    const angulo = !!nodeDatum.attributes
      ? !nodeDatum.attributes.completenessActive
        ? 360
        : (nodeDatum.attributes.completenessPercent / 100) * 360
      : 360
    const x1 = radio
    const y1 = 0
    const x2 = radio * Math.cos((angulo * Math.PI) / 180)
    const y2 = radio * Math.sin((angulo * Math.PI) / 180)

    const pathData = `
    M ${x1},${y1}
    A ${radio},${radio} 0 ${angulo > 180 ? 1 : 0} 1 ${x2},${y2}
    R 180
    L 0,0
    Z
  `
    if (nodeDatum.__rd3t.depth === 0) return
    return (
      <g strokeWidth={0}>
        <path
          strokeWidth={1}
          fill={
            !!nodeDatum.attributes
              ? nodeDatum.attributes.completenessActive
                ? Number(nodeDatum.attributes.completenessPercent) === 100
                  ? "green"
                  : "red"
                : "white"
              : "white"
          }
          d={pathData}
        />
        <circle
          strokeWidth={1}
          r={25}
          fill={
            nodeDatum.children && nodeDatum.children.length > 0
              ? "yellow"
              : "orange"
          }
        ></circle>
        {nodeDatum.attributes && nodeDatum.attributes.completenessActive && (
          <text fill="black" x={-21} strokeWidth={1} fontSize={16} y={0}>
            {nodeDatum.attributes.completenessPercent.toString().slice(0, 4)}%
          </text>
        )}
        {nodeDatum.attributes && nodeDatum.attributes.completenessActive && (
          <text fill="black" x={-7} strokeWidth={1} fontSize={16} y={15}>
            {nodeDatum.attributes.completenessWeight.toString().slice(0, 4)}
          </text>
        )}

        <text fontSize={18} y={45} x={-60} fill="white">
          {nodeDatum.name.length < maxTitleLength
            ? nodeDatum.name
            : nodeDatum.name.slice(0, maxTitleLength) + "..."}
        </text>
        {nodeDatum.attributes &&
          nodeDatum.attributes.isAssigned &&
          collaboratorsCache[nodeDatum.attributes.assignedTo] && (
            <g>
              <image href={imgRoute} y={55} x={-50} width="30px" />
              <text fill="white" x={-15} strokeWidth={0} fontSize={18} y={75}>
                {collaboratorsCache[nodeDatum.attributes.assignedTo].username}
              </text>
            </g>
          )}
      </g>
    )
  }

  const getDynamicPathClass = ({ source, target }, orientation) => {
    if (target.depth === 1) {
      return "hiddenLink"
    }
    // Style it as a link connecting two branch nodes by default.
    return "link__to-branch"
  }

  return (
    <div
      ref={ref}
      style={{ height: `calc(100vh - ${50}px)` }}
      className={"w-full "}
    >
      <div className={"touch-manipulation w-full h-full bg-color2 relative"}>
        {yjsData.tasksY.length > 0 && (
          <a className={"absolute text-white bottom-2 left-2"}>
            Arrastra con el ratón para despazarte y usa la rueda del ratón para
            hacer zoom.
          </a>
        )}
        {yjsData.tasksY.length === 0 && (
          <a className={"absolute text-white bottom-2 left-2"}>
            Toavía no has creado ninguna tarea.
          </a>
        )}
        <Tree
          lrootNodeClassName="node__root"
          branchNodeClassName="node__branch"
          leafNodeClassName="node__leaf"
          collapsible={false}
          translate={{ x: 20, y: translateHeight }}
          data={treeData}
          renderCustomNodeElement={renderRectSvgNode}
          pathClassFunc={getDynamicPathClass}
        />
      </div>
    </div>
  )
}

export default Graph

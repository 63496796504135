import {useSelector} from "react-redux";
import {useEffect} from "react";
import DefaultImage from '../../common/DefaultImage.js'

const UserImg = ({changingImage, className}) =>{
    const user = useSelector((state) => state.user.userInfo)
    const defaultImg= DefaultImage
    const imgRoute = user.defaultimg? defaultImg: import.meta.env.VITE_BACKEND_URL+user.imgdata
    useEffect(()=>{},[changingImage, user.imgdata])
    return(
        <img src={imgRoute} className={"shrink-0 min-w-full min-h-full " + className}/>
    )
}

export default UserImg
const StackGrid = () => {
  const stackGridImgs = [
    {
    img: '/presentationAssets/stackLogos/react.png',
    alt: 'react'
    },
    {
    img: '/presentationAssets/stackLogos/tailwind.png',
    alt: 'tailwind'
    },
    {
    img: '/presentationAssets/stackLogos/yjs.png',
    alt: 'yjs'
    },
        {
    img: '/presentationAssets/stackLogos/hocuspocus.png',
    alt: 'hocuspocus'
    },
    {
    img: '/presentationAssets/stackLogos/go.png',
    alt: 'go'
    },
        {
    img: '/presentationAssets/stackLogos/fiber.png',
    alt: 'go'
    },
    {
    img: '/presentationAssets/stackLogos/mongo.png',
    alt: 'mongo'
    },
    {
    img: '/presentationAssets/stackLogos/kubernetes.png',
    alt: 'kubernetes'
    },
    {
    img: '/presentationAssets/stackLogos/docker.png',
    alt: 'docker'
    },
]
  return (
    <div className="gap-4 grid grid-cols-[repeat(auto-fill,minmax(90px,1fr))] auto-rows-fr lg:grid-cols-[repeat(auto-fill,minmax(120px,1fr))]">
      {stackGridImgs.map((x, i) => (
        <div
          key={i}
          className="h-auto w-full object-cover flex justify-center items-center"
        >
          <img src={x.img} alt="item.alt" />
        </div>
      ))}
    </div>
  )
}

export default StackGrid
